<template>
  <div class="web_box">
    <van-nav-bar
      title=""
      left-arrow
      @click-left="toBack"
      fixed
      class="header_class"
      :border="false"
    >
      <div class="back_icon" slot="left" />
    </van-nav-bar>
    <div class="search_box">
      <span class="search_icon"></span>
      <input
        type="text"
        @input="search(searchVal)"
        v-model="searchVal"
        placeholder="关键字搜索"
      />
    </div>
    <div class="tab_list">
      <van-tabs
        v-model="active"
        background="#12172c"
        title-active-color="#00CCFF"
        title-inactive-color="#37708F"
        color="#00CCFF"
        line-height="2"
        @click="changeTab(active)"
      >
        <van-tab
          v-for="item in tabList"
          :key="item.id"
          :title="item.name"
          :name="item.id"
        ></van-tab>
      </van-tabs>
    </div>
    <!-- 列表 -->

    <van-list
      class="pro_list"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <lists v-if="dataList.length !== 0" :dataList="dataList" :type="2" />
    </van-list>
  </div>
</template>
<script>
import {findPrescriptionData, findPrescriptionType} from "@/utils/api"; // 导入我们的api接口
import lists from "../../components/mall/list";
export default {
  components: {
    lists,
  },
  data() {
    return {
      tabList: [],
      dataList: [],
      active: "",
      searchVal: "",
      loading: false,
      finished: false,
      total: "",
      parame: {
        page: 1,
        limit: 10,
        type: "1",
        title: "",
        prescriptionTypeId: 1,
      },
    };
  },
  mounted() {
    findPrescriptionType({})
      .then((res) => {
        this.tabList = res.data.prescriptionTypeList;
        this.parame.prescriptionTypeId = this.tabList[0].id;
      })
      .catch();
  },
  created() {
    // if (this.$route.query.formula) {
    //   // console.log(this.$route.query("formula"));
    //   this.searchVal = this.$route.query("formula");
    // }
    this.active = this.$route.query.active;
    this.parame.type = this.$route.query.active;
    this.parame.title = this.$route.query.formula;
    this.parame.page = 0;
    this.searchVal = this.$route.query.formula;
    this.dataList = [];

    this.onLoad();
  },
  methods: {
    // 切换导航
    changeTab(active) {
      this.dataList = [];
      this.loading = true;
      this.finished = false;
      this.parame = {
        type: active,
        page: 0,
        limit: 10,
        title: "",
        prescriptionTypeId: active,
      };
      this.$router.replace({
        path: "/formulaShop",
        query: {
          active: active,
        },
      });

      this.onLoad();
    },
    // 搜索
    search(val) {
      let that = this;
      that.dataList = [];
      that.parame = {
        type: that.active,
        page: 0,
        limit: 10,
        title: val,
      };
      that.loading = true;
      that.finished = false;
      setTimeout(() => {
        that.onLoad();
      }, 50);
    },
    // 上拉加载 // 获取数据
    onLoad() {
      let that = this;
      // 异步更新数据
      that.parame.page++;
      findPrescriptionData(that.parame)
        .then((res) => {
          let rows = res.data.list; //请求返回当页的列表
          that.loading = false;
          that.total = res.data.totalCount;
          if (rows == null || rows.length === 0) {
            // 加载结束
            that.finished = true;
            return;
          }

          // 将新数据与老数据进行合并
          that.dataList = that.dataList.concat(rows);

          //如果列表数据条数>=总条数，不再触发滚动加载
          if (that.dataList.length >= that.total) {
            that.finished = true;
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.tab_list {
  width: 100%;
  height: 88px;
  margin-top: 88px;
  background-color: #12172c;
  position: fixed;
  top: 0;
}
.search_box {
  position: fixed;
  width: 476px;
  height: 60px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../../assets/mall/search_border.png");
  top: 14px;
  z-index: 9;
  left: 137px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .search_icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../../assets/mall/search_icon.png");
  }
  input {
    width: 220px;
    height: 40px;
    padding: 0 20px;
    border: none;
    background: transparent;
    outline: none;
    color: #fff;
  }
}
.pro_list {
  padding-top: 186px;
}
</style>
<style lang="scss">
.van-tab__text {
  font-size: 26px;
}
</style>
